<template>
  <v-list-item link @click="_click()" :to="to">
    <v-list-item-icon class="align-center">
      <v-icon :color="color">{{icon}}</v-icon>
    </v-list-item-icon>
    <v-list-item-title class="align-center">
      {{title}}
    </v-list-item-title>
    <v-list-item-action class="align-center">
      <sheet-status :status="status"></sheet-status>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import SheetStatus from '../molecules/SheetStatus'

export default {
  components: {
    SheetStatus,
  },
  props: {
    color: String,
    icon: String,
    title: String,
    status: String,
    to:{
      type: String,
      default: "",
    }
  },
  methods: {
    _click(){
      this.$emit('click')
    },
  }
}
</script>
<style lang="scss" scoped>
.align-center {
  align-self: center;
}
</style>