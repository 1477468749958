import { render, staticRenderFns } from "./NewStrategyEditPage.vue?vue&type=template&id=7010ceea&scoped=true&"
import script from "./NewStrategyEditPage.vue?vue&type=script&lang=js&"
export * from "./NewStrategyEditPage.vue?vue&type=script&lang=js&"
import style0 from "./NewStrategyEditPage.vue?vue&type=style&index=0&id=7010ceea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7010ceea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VIcon,VMain,VScrollYReverseTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
