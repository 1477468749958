<template>
  <v-card class="ma-2 pa-0 py-1 white" light flat>
    <v-card-text-with-label
      labelColor="#979EAA"
      textColor="white"
      labelText="ステータス"
      :contentText="statusName"
    ></v-card-text-with-label>
    <v-card-text-with-label
      labelColor="#979EAA"
      textColor="white"
      labelText="業界"
      :contentText="project.industry"
    ></v-card-text-with-label>
    <v-card-text-with-label
      labelColor="#979EAA"
      textColor="white"
      labelText="事業フェーズ"
      :contentText="project.businessPhase"
    ></v-card-text-with-label>
    <v-card-text-with-label
      labelColor="#979EAA"
      textColor="white"
      labelText="課題分類"
      :contentText="project.issue.map((i) => i.issue).join('<br>')"
    ></v-card-text-with-label>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import VCardTextWithLabel from "../molecules/VCardTextWithLabel";
import MasterData from "../../../utils/MasterData";
export default {
  components: {
    VCardTextWithLabel,
  },
  computed: {
    ...mapGetters(["project"]),
    statusName() {
      return (
        MasterData.statuses.find((e) => e.id == this.project.status) || {
          status: "不明",
        }
      ).status;
    },
  },
};
</script>
