<template>
  <div>
    <v-card
      outlined
      color="#FAFAFA"
      class="item-card ma-0 px-4 pt-4 pb-1 rounded-lg"
      :class="checked ? 'border-active' : 'border'"
      :elevation="checked ? '5' : '0'"
      width="100%"
    >
      <div class="d-flex flex-column">
        <div class="check-area">
          <div class="check-area__checkbox">
            <v-checkbox
              :input-value="value"
              :value="selectVal"
              @change="$emit('input', $event)"
              hide-details
              class="py-0 px-0 my-0 mx-0"
              background-color="white"
              color="btPrimary"
              multiple
            ></v-checkbox>
          </div>
          <v-img :src="_thumbnail" :aspect-ratio="16/9" @click="toggleCheckbox()" class="thumbnail"></v-img>
        </div>
        <v-card-title class="px-1 pt-1 pb-0">
          <div class="d-inline-flex align-top font-weight-bold text-subtitle-1">
            <div class="label text-wrap pt-1">{{ caption }}</div>
          </div>
        </v-card-title>
        <!-- <v-card-text class="px-1 py-0 text-right">
          <v-btn
            text
            color="btPrimary"
            style="text-transform: none"
            class="px-1"
            small
            @click="onClickPreview(_thumbnail, caption)"
          >
            <v-icon>mdi-magnify-plus-outline</v-icon>
            <span>Preview</span>
          </v-btn>
        </v-card-text> -->
      </div>
    </v-card>

    <!-- プレビュー拡大ダイアログ -->
    <v-dialog v-model="dialog" max-width="720">
      <div>
        <v-btn icon @click="dialog = false">
          <v-icon x-large color="white">mdi-close</v-icon>
        </v-btn>
        <v-img :src="src" :alt="currentCaption"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import YesNoDialog from "../atoms/YesNoDialog.vue";

export default {
  components: {
    YesNoDialog,
  },
  props: {
    caption: null,
    value: null,
    preview: null,
    selectVal :null,
  },
  data() {
    return {
      select: true,

      // プレビューダイアログ用
      dialog: false,
      currentCaption: "",
      src: "",
    }
  },
  computed: {
    _thumbnail() {
      return !!this.preview ? this.preview : "/img/no_image.png";
    },

    checked() { return this.value.includes(this.selectVal) },
  },
  methods: {
    onClickPreview(src, caption) {
      this.src = src;
      this.currentCaption = caption;
      this.dialog = true;
    },
    toggleCheckbox() {
      // 画像をクリックされたときにチェックボックスを切り替える
      let currentVal = this.value;
      if(this.checked) {
        const index = currentVal.findIndex(n => n === this.selectVal);
        currentVal.splice(index, 1)
      } else {
        currentVal.push(this.selectVal)
      }

      this.$emit('input', currentVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-card {
  width: fit-content;
  height: fit-content;
}
.label {
  display: -webkit-box;
  line-height: 1.3em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  height: 47px;
}

.check-area {
  position: relative;

  &__checkbox {
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: 5;

    .v-input--selection-controls__input {
      margin: 0;
    }
  }
}

.border {
  border: 4px solid #dee0e1 !important;
  border-radius: 20px !important;
}

.border-active {
  border: 4px solid $bt-primary !important;
  border-radius: 20px !important;
}

.thumbnail {
  border: 2px solid #dee0e1 !important;
}
</style>

<style lang="scss">
.check-area {
  &__checkbox {
    .v-input--selection-controls__input {
      margin: -4px;
    }
  }
}
</style>
