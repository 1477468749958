<template>
  <v-navigation-drawer
    v-if="project"
    color="#F1F4F5"
    app
    dark
    permanent
    :mini-variant.sync="mini"
    stateless
    clipped
  >
    <div class="d-flex flex-column justify-space-between" style="height: 100%;">
      <div>
        <div class="d-flex justify-end pr-2 pt-2">
          <v-btn
            icon
            class="white"
            @click.stop="mini = !mini"
            color="blue darken-4"
          >
            <v-icon v-show="mini">mdi-chevron-right</v-icon>
            <v-icon v-show="!mini">mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <div class="mb-3">
          <v-list dense class="menu">
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in menuItems"
                :key="i"
                :to="item.to"
                :class="mini ? 'py-1 px-0 justify-center' : 'py-1'"
                v-show="!item.hidden"
                exact
                :active-class="item.to ? '' : 'unselectable'"
                @click="callFunc(item.action, $event)"
              >
                <v-btn fab small depressed color="#7BAAD4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-btn>
                <v-list-item-content class="ms-4">
                  <v-list-item-title
                    v-text="item.text"
                    class="blue--text text--darken-4 font-weight-bold"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <v-divider></v-divider>
        <v-expand-transition>
          <project-description v-show="!mini"></project-description>
        </v-expand-transition>
        <v-btn-with-external-link
          class="mt-6 ml-1"
          v-show="!mini"
          :href="project.siteUrl"
          text="対象URL"
          :disabled="project.siteUrl ? false : true"
        ></v-btn-with-external-link>
        <v-btn-with-external-link
          class="ml-1"
          v-show="!mini"
          :href="project.analyticsUrl"
          text="数値データ"
          :disabled="project.analyticsUrl ? false : true"
        ></v-btn-with-external-link>
        <v-btn-with-external-link
          class="ml-1"
          v-show="!mini && !!project.salesforceUrl"
          :href="project.salesforceUrl"
          text="Salesforce"
        ></v-btn-with-external-link>

        <!-- SNSアカウント -->
        <v-btn-with-external-link
          v-for="(item, idx) in project.snsAccount"
          :key="idx"
          class="ml-1"
          v-show="!mini"
          :href="item.accountUrl"
          :text="item.serviceName"
        ></v-btn-with-external-link>
      </div>
      <div class="pb-3 mt-6" v-show="!mini">
        <v-btn v-if="!project.archivedAt" text small color="grey" width="100%" @click="deleteDialog=true" class="justify-start">
          <span class="red--text">
            <v-icon small>mdi-trash-can</v-icon>
            プロジェクトを削除
          </span>
        </v-btn>
      </div>
    </div>

    <!-- 振り返りするか確認するダイアログ -->
    <yes-no-dialog
      v-model="reviewDialog"
      message="振り返りを実施しますか？"
      yesText="実施する"
      noText="戻る"
      @clickYes="reviewYes()"
      @clickNo="reviewDialog=false"
    />

    <!-- プロジェクト削除 -->
    <yes-no-dialog
      v-model="deleteDialog"
      message="プロジェクトを削除しますか？"
      yesText="削除する"
      noText="戻る"
      center
      @clickYes="deleteYes()"
      @clickNo="deleteDialog=false"
    />

    <!-- スライド選択ダイアログ -->
    <slide-export-dialog-multi
      v-model="slideExportDialog"
      @selected="exportGoogleSlides($event)"
    ></slide-export-dialog-multi>

    <!-- スライド出力ダイアログ -->
    <slide-export-completed-dialog
      v-model="slideExportCompletedDialog"
      :slideUrl="slideUrl"
      @closed="slideExportCompletedDialog = false"
    >
    </slide-export-completed-dialog>

    <v-dialog v-model="overlay" persistent width="400">
      <v-card color="white">
        <v-card-text class="pa-3">
          作成中...
          <v-progress-linear indeterminate color="primary" class="mb-2"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from "vuex";

import UserInformation from "../molecules/UserInformation";
import VListItemWithLink from "../atoms/VListItemWithLink";
import ProjectDescription from "../organisms/ProjectDescription";
import VBtnWithExternalLink from "../molecules/VBtnWithExternalLink";
import YesNoDialog from "../atoms/YesNoDialog.vue";

import {RepositoryFactory} from '../../../api/RepositoryFactory'
import SlideExportDialogMulti from "../molecules/SlideExportDialogMulti.vue";
import SlideExportCompletedDialog from "../molecules/SlideExportCompletedDialog.vue";

export default {
  components: {
    UserInformation,
    VListItemWithLink,
    ProjectDescription,
    VBtnWithExternalLink,
    YesNoDialog,

    SlideExportDialogMulti,
    SlideExportCompletedDialog,
  },
  data() {
    return {
      mini: false,

      //スライド選択ダイアログ表示用
      slideExportDialog: false,

      //スライド出力結果ダイアログ表示用
      slideExportCompletedDialog: false,
      slideUrl: null,
      resultCode: null,

      //スライド出力loadingモーション用
      overlay: false,

      // 振り返り実施確認ダイアログ用
      reviewDialog: false,

      deleteDialog: false,
    };
  },
  computed: {
    ...mapGetters(["project", "hasWritePermission", "userInfo"]),
    menuItems() {
      return [
        {
          text: "プロジェクトTOP",
          icon: "mdi-home-edit",
          to: { name: "StrategyTopPage", query: this.getQueryParamProjectReadonly() },
        },
        {
          text: "プロジェクト編集",
          icon: "mdi-square-edit-outline",
          to: {
            name: "ProjectEditInsideFrame",
            params: { projectId: this.project.id },
            query: this.getQueryParamProjectReadonly(),
          },
        },
        {
          text: "戦略ノート",
          icon: "mdi-notebook",
          to: {
            name: "StrategyOverview",
            params: { projectId: this.project.id },
            query: this.getQueryParamProjectReadonly()
          },
        },
        { text: "スライド出力", icon: "mdi-application-export", to: undefined, action: this.openSlideExportDialog},
        { 
          text: "振り返り",
          icon: "mdi-reply-circle",
          to: undefined,
          action: this.clickReview,
        },
        { 
          text: "過去ログ",
          icon: "mdi-database",
          to: { name: "ProjectLog", query: this.getQueryParamProjectReadonly() }
        },
      ];
    },
  },
  methods: {
    callFunc(func, e) {
      if(func) func(e);
    },
    
    async exportGoogleSlides(val) {

      //loadingモーションON
      this.overlay = true;

      //TODO: 仮でスライド出力APIをここで呼び出し
      const slidesRepository = RepositoryFactory.get('slides');
      const projectId = this.project.id;

      const params = {
        type: val.templateType,
        pageIds: val.slideIds,
      };

      const response = await slidesRepository.export2( projectId, params );
      if (response.data.data.slide) {
        console.log('slidesExport response:', response.data.data.slide);
        this.slideUrl = response.data.data.slide.url;
      } else {
        this.slideUrl = '';
      }

      this.slideExportDialog = false;
      this.slideExportCompletedDialog = true;

      //loadingモーションOFF
      this.overlay = false;
    },

    openSlideExportDialog() {
      this.slideExportDialog = true;
    },

    openReviewDialog() {
      this.reviewDialog = true;
    },

    reviewYes() {
      this.$router.push({name: "ProjectReview", query: this.getQueryParamProjectReadonly()});
      this.reviewDialog = false;
    },

    async deleteYes() {
      // アーカイブがあるか確認する
      const ProjectRepository = RepositoryFactory.get("project");
      let param = { userId: this.userInfo.userId };

      // Ph1のprojectはparentIdを持たないのでその場合は自身のidを設定
      let id = !!this.project.parentId
        ? this.project.parentId
        : this.project.id;

      let response = await ProjectRepository.getGroup(id, param);
      if (response.data.result && response.data.resultCode === "0000") {
        const rst = response.data.data.projects;
        
        // アーカイブを持つ場合は削除不可
        if(rst.filter(item => item.id !== this.project.id).length) {
          this.$store.commit('setToast', {msg: 'アーカイブを持つプロジェクトは削除できません。', color: 'red', timeout: 4000});
          this.deleteDialog = false;
          return;
        }

      } else {
        this._sendError(response.data.message);
      }

      // プロジェクト(削除)API
      response = await ProjectRepository.delete(this.project.id);
      if (response.data.result && response.data.resultCode === "0000") {
        const rtn = response.data.data.project;
        console.log(rtn);
      } else {
        this._sendError(response.data.message);
      }

      this.deleteDialog = false;
      this.$router.push({name: "TopPage"});
    },

    clickReview() {
      if(this.hasWritePermission && !(this.$route.query?.mode == "readonly")) {
        this.openReviewDialog();
      } else {
        this.reviewYes();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer ::v-deep .v-navigation-drawer__content {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: none;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    // border-radius: 10px;
    box-shadow: none;
  }
}

.menu {
  .v-list-item-group {
    .v-list-item--active {
      color: transparent !important;
      background-color: #e4e4e4;
      .v-btn {
        background-color: $bt-primary !important;
      }

      &.unselectable {
        color: inherit;
        background-color: inherit;
      }
    }

  }
}
</style>
