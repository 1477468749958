<template>
  <div v-if="project">
    <!-- プロジェクト関係者 -->
    <div class="d-flex" style="width: fit-content;">
      <user-icon
        v-for="(item, i) in project.affiliatedUser"
        :key="i"
        :icon="item.userImageUrl"
        :familyName="item.lastName"
        :givenName="item.firstName"
        :departmentName="item.departmentName"
        :color="item.userColor"
      ></user-icon>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UserIcon from '../molecules/UserIcon'
export default {
  components: {
    UserIcon,
  },
  computed: {
    ...mapGetters([
      'project',
    ])
  },


}
</script>
