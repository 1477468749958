<template>
  <div>
    <v-dialog v-model="open" max-width="1200" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark flat color="btPrimary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>スライド出力</v-toolbar-title>
        </v-toolbar>
        <v-tabs v-model="tab" vertical>
          <v-tab v-for="d in templateDefs" :key="d.type" class="justify-start">
            <v-icon left>mdi-file-powerpoint-box-outline</v-icon>
            {{d.caption}}
          </v-tab>
          <v-tab-item v-for="d in templateDefs" :key="d.type" >
            <v-card flat min-height="400" rounded="0" class="template-content pa-3 d-flex flex-column">
              <v-card-title>{{d.caption}}</v-card-title>
              <v-card-text class="flex-grow-1">
                <div class="mb-2">{{ d.description }}</div>
                <v-divider></v-divider>
                <div class="mt-2">以下から出力するページを選択してください</div>
                <div class="d-flex justify-end">
                  <v-checkbox
                    :label="!isAll ? '全スライドを選択' : '全スライドを解除'"
                    v-model="isAll"
                    hide-details
                    class="mt-2 mb-3 me-5"
                  />
                </div>
                <div v-if="templateDefs && templateDefs.length > 0" class="page-selector-area d-flex flex-wrap py-3 pr-2">
                  <v-row>
                    <v-col v-for="i in d.items" :key="i.val" v-show="i.selectable !== false" md="3">
                      <div>
                        <slide-export-parts-card
                          v-model="selected[d.type]"
                          :caption="i.caption"
                          :selectVal="i.val"
                          :preview="i.preview"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col></v-col>
                    <v-col>
                      <v-btn
                        color="btAccent"
                        class="white--text mx-2 mb-3"
                        width="190"
                        depressed
                        @click="selectedOK"
                        >出力開始</v-btn
                      >
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {RepositoryFactory} from '../../../api/RepositoryFactory'

import SlideExportPartsCard from '../molecules/SlideExportPartsCard.vue';

export default {
  props: ["value"],
  components: {
    SlideExportPartsCard,
  },
  data() {
    return {
      tab: 0,
      templateDefs: [],
      selected: {},
    }
  },
  computed: {
    open: {
      get() { return !!this.value },
      set(val) {
        this.$emit('input', val);
      },
    },

    isAll: {
      get() {
        // パーツ選択肢が全選択されているときのみtrueを返す
        const d = this.templateDefs[this.tab]
        const templateType = d.type;

        if (this.selected[templateType].length == d.items.length) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        const d = this.templateDefs[this.tab]
        const templateType = d.type;

        // パーツ選択肢が全選択されているときのみチェックを外す
        if (this.selected[templateType].length == d.items.length) {
          // 選択リストを一旦空にする
          this.selected[templateType] = [];
          // 表紙などは追加する
          d.items.forEach(item => {
            if (item.selectable ==  false) this.selected[templateType].push(item.val);
          });
        } else {
          this.selected[templateType] = d.items.map((item) => item.val);
        }
      },
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    close() {
      this.open = false;
    },
    selectedOK() {
      this.open = false;

      const templateType = this.templateDefs[this.tab].type;
      const slideIds = this.selected[templateType];
      this.$emit("selected", { templateType, slideIds });
    },
    async init() {
      const ret = await this.getTemplates();

      this.selected = ret.reduce((base, t) => {
        base[t.type] = t.items.map(i => i.val);
        return base;
      }, {});
      this.templateDefs = ret;
    },
    async getTemplates() {
      const slidesRepository = RepositoryFactory.get('slides');

      const ret = await slidesRepository.templates();
      if (ret.data) {
        return ret.data;
      } else {
        return [];
      }
    },

  },
  watch: {
    async value(val) {
      if (val) {
        this.init();
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.template-content {
  border-left: solid 1px rgb(216, 216, 216);

  .page-selector-area {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollAreaY();

    .page-selector {
      width: fit-content;
      margin-right: 40px;
    }
  }
}
.preview-wrap {
  max-width: 700px;
}
</style>
