<template>
  <v-menu
    bottom
    min-width="200px"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="px-1"
        text
        v-on="on"
        style="height: fit-content;"
        rounded
      >
        <div>
        <v-avatar :color="colorVal" size="36">
          <img v-if="icon"
            :src="icon"
            :alt="iconLabel"
          >
          <span v-else class="white--text text-h8">{{iconLabel}}</span>
        </v-avatar>
        <div class="white--text text-caption">{{ familyName }} {{ givenName }}</div>
          
        </div>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar :color="colorVal" size="48" class="mb-2">
            <img v-if="icon"
              :src="icon"
              :alt="iconLabel"
            >
            <span v-else class="white--text text-h8" :color="colorVal">{{iconLabel}}</span>
          </v-avatar>
          <h3>{{ familyName }} {{ givenName }}</h3>
          <p class="caption ma-0 mt-1">
            {{ departmentName }}
          </p>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    icon: String,
    color: String,
    familyName: String,
    givenName: String,
    departmentName: String
  },
  computed: {
    iconLabel() {
      return this.$props.givenName ? this.$props.givenName.slice(0, 1) + this.$props.familyName.slice(0, 1) : this.$props.familyName.slice(0, 2);
    },
    colorVal() {
      return this.color ?? 'light-blue'
    },
  }
}
</script>
