<template>
  <v-card flat>
    <v-toolbar :height="height" flat>
      <v-container fluid>
        <v-row class="align-center">
          <v-col cols="3">
            <div class="px-2">
              <router-link :to="{ name: 'TopPage' }">
                <v-img
                  alt="Branding Bank"
                  max-height="46"
                  max-width="247"
                  min-width="247"
                  src="@/assets/images/bblogo.png"
                  class="d-none d-md-block"
                ></v-img>
                <v-img
                  alt="Branding Bank"
                  max-height="46"
                  max-width="46"
                  src="@/assets/images/bblogo-icon.png"
                  class="d-block d-md-none"
                ></v-img>
              </router-link>
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-space-around">
            <div class="mx-2">
              <v-btn text rounded :to="{name: 'TopPage'}">
                <v-icon color="primary">mdi-home</v-icon>
                <span class="text-body-1">TOP</span>
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn text rounded :to="{name: 'SearchPage'}">
                <v-icon color="btPrimary">mdi-magnify</v-icon>
                <span class="text-body-1">プロジェクト検索</span>
              </v-btn>
            </div>
            <!-- <div class="mx-2">
              <v-btn text rounded>
                <v-icon color="btPrimary">mdi-magnify</v-icon>
                <span class="text-body-1">戦略パーツ検索</span>
              </v-btn>
            </div> -->
          </v-col>
          <v-col cols="3" class="menu-icons d-flex justify-end">
            <div class="mr-7">
              <help-menu-icon></help-menu-icon>
            </div>
            <div class="mr-2">
              <user-menu-icon></user-menu-icon>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
  </v-card>
</template>

<script>
import UserMenuIcon from "../molecules/UserMenuIcon.vue";
import HelpMenuIcon from "../molecules/HelpMenuIcon.vue";

export default {
  components: {
    UserMenuIcon,
    HelpMenuIcon,
  },
  props: {
    height: {
      type: Number,
      required: true,
      default: 50,
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-icons {
  z-index: 999;
}
</style>
