<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn icon color="btPrimary" dark v-bind="attrs" v-on="on">
        <v-icon size="40">mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link v-for="(item, i) in menuItems" :key="i" :to="item.to" @click="item.action">
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [],
    };
  },
  created() {
    this.initMenu();
  },
  methods: {
    initMenu() {
      this.menuItems = [{ 
        label: "ユーザーマニュアルを見る", 
        to: undefined,
        action: () => {
          const url = "https://docs.google.com/presentation/d/1vKlkfELVi4KndtNR--o713e86l2le4A0fmgzTmRyRlc/"
          window.open(url, '_blank')
        }
      }];
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  color: $bt-primary!important;
}
</style>
