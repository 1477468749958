<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
          <v-avatar :color="userInfo.userImageUrl ? '' : userInfo.userColor" size="42">
            <img v-if="userInfo.userImageUrl"
              :src="userInfo.userImageUrl"
            >
            <span v-else class="white--text text-h8">{{iconLabel}}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link v-for="(item, i) in menuItems" :key="i" :to="item.to" @click="item.action()">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <user-edit-dialog v-model="editUserProfile" :userData="editTarget" :add="false" :profile="true" @ok="updateUserProfile($event)"></user-edit-dialog>
    <!-- 登録確認ダイアログ -->
    <yes-no-dialog
      v-if="logoutDialog"
      :message="'ログアウトしますか？'"
      v-model="logoutDialog"
      yesText="ログアウトする"
      noText="キャンセル"
      center
      @clickYes="logout()"
      @clickNo="logoutDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserEditDialog from '../organisms/master/UserEditDialog'
import YesNoDialog from '../atoms/YesNoDialog.vue'

export default {
  components: {
    UserEditDialog,
    YesNoDialog,
  },
  data() {
    return {
      menuItems: [],
      editTarget: {},
      editUserProfile: false,
      logoutDialog: false
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    iconLabel() {
      const firstName = this.userInfo.firstName;
      const lastName = this.userInfo.lastName;
      return firstName ? firstName.slice(0, 1) + lastName.slice(0, 1) : lastName.slice(0, 2);
    },
  },
  created() {
    this.initMenu();
  },
  methods: {
    initMenu() {
      const userProfile = {
        label: "ユーザープロフィール", 
        icon: "mdi-account",
        action: async () => {
          this.editTarget = this.userInfo;
          this.editUserProfile = true;
        }
      };

      const masterMente = {
        label: "マスタメンテナンス", 
        icon: "mdi-axis",
        action: () => {
          if(this.$route.name != 'MasterMente_user') {
            this.$router.push({ name: 'MasterMente_user' })
          }
        }
      };

      const logout = {
        label: "ログアウト", 
        icon: "mdi-logout",
        action: () => { this.logoutDialog = true },
      };

      if (this.userInfo.superUser) {
        this.menuItems = [ masterMente, userProfile, logout ];
      } else {
        this.menuItems = [ userProfile, logout ];
      }
    },

    updateUserProfile(data) {
      this.editUserProfile = false;
      this.$store.commit('userInfo', data);
    },

    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpirationDate')
      this.$store.commit('accessToken', null)
      this.$router.push({ name: 'LoginPage' });
    }
  },
};
</script>
