<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <!-- タイトル -->
        <v-card-title v-if="slideUrl">
          <v-icon color="green" size="36" left class="mr-2">mdi-check-circle</v-icon>
          スライドを出力しました
        </v-card-title>
        <v-card-title v-else>
          <v-icon color="red" size="36" left class="mr-2">mdi-close-circle</v-icon>
          スライド出力に失敗しました
        </v-card-title>

        <!-- メッセージ -->
        <v-card-text v-if="slideUrl">
          こちらのURLからご確認ください。<br>
          <a :href="slideUrl" target="_blank">{{slideUrl}}</a>
        </v-card-text>
        <v-card-text v-else> 問題が発生しました。時間をおいてから再度お試しください。</v-card-text>

        <!-- 終了ボタン -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="pa-3 text-body-1"
            width="126"
            color="grey lighten-2"
            large
            depressed
            @click="close()"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["value", "slideUrl"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("closed");
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.dialog = val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>