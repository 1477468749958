<template>
  <v-row class="mx-2 my-1 d-flex flex-row flex-wrap" align-content="center">
    <v-col cols="5" class="ma-0 pa-0" >
      <v-chip
        style="width: 100%"
        class="mr-2 justify-center"
        :color="labelColor"
        :text-color="textColor"
        label
        small
      >{{labelText}}</v-chip>
    </v-col>
    <v-col cols="7" class="ma-0 py-0 pr-0" align-self="center">
      <div class="text-caption black--text" v-html="contentText"></div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {
  },
  props: {
    labelColor: String,
    textColor: String,
    labelText: String,
    contentText: String,
  },
};
</script>
<style lang="scss" scoped>
</style>