<template>
  <v-app-bar
    id="multiRowAppbarContainer"
    app
    clipped-left
    color="white"
    :height="multiRow ? upperBarHeight + lowerBarHeight : upperBarHeight"
    flat
  >
    <v-container fluid class="px-0 py-0">
      <v-row no-gutters>
        <v-col cols="12">
          <slot></slot>
        </v-col>
        <v-col v-show="multiRow" cols="12">
          <slot name="second"> </slot>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    "multi-row": { default: false },
    "upper-bar-height": { require: true },
    "lower-bar-height": { default: 0 }
  },
};
</script>

<style lang="scss" scoped>
#multiRowAppbarContainer {
  z-index: 100;
}
</style>

<style lang="scss">
#multiRowAppbarContainer > .v-toolbar__content {
  padding: 0;
}
</style>
