<template>
  <v-avatar
    :color="color"
    size="27px"
  >
    <v-icon
      small
      :color="iconColor"
    >{{icon}}</v-icon>
  </v-avatar>
</template>
<script>
export default {
  props: {
    status: String
  },
  computed: {
    icon() {
      if (this.$props.status === 'notInput') {
        return 'mdi-circle-off-outline';
      } else if (this.$props.status === 'edit') {
        return 'mdi-circle-edit-outline';
      } else if (this.$props.status === 'done') {
        return 'mdi-checkbox-marked-circle-outline';
      } else {
        return null;
      }
    },
    color() {
      if (this.$props.status === 'notInput') {
        return 'blue-grey lighten-3';
      } else if (this.$props.status === 'edit') {
        return 'amber darken-2';
      } else if (this.$props.status === 'done') {
        return 'white';
      } else {
        return null;
      }
    },
    iconColor(){
      if (this.$props.status === 'notInput') {
        return 'white';
      } else if (this.$props.status === 'edit') {
        return 'white';
      } else if (this.$props.status === 'done') {
        return 'indigo darken-1';
      } else {
        return null;
      }
    }
  },
}
</script>
