<template>
  <v-card class="strategy-edit-topbar" flat tile>
    <v-toolbar :height="height" color="#0761b4" flat>
      <v-container fluid>
        <v-row class="justify-start">
          <div class="d-flex align-center me-8">
            <div v-if="project.projectIconUrl" class="mr-3">
              <v-avatar size="60px" class="ma-0 pa-0 flex-grow-0" tile>
                <img :src="project.projectIconUrl" />
              </v-avatar>
            </div>
            <div v-else class="no-image-col mr-3"></div>

            <div class="strategy-edit-topbar__label">
              <div class="text-h6 white--text top">
                {{ project.projectName }}
              </div>
              <div class="text-overline white--text bottom">
                {{ project.clientName }}／{{ project.businessName }}
              </div>
            </div>
          </div>
          <div v-if="!!project.archivedAt" class="d-flex align-center">
            <v-card
              class="py-2 px-5"
              height="fit-content"
              elevation="0"
            >
              <div class="btAccent--text text-body-1">アーカイブ済み</div>
              <router-link :to="{
                name: 'StrategyTopPage',
                params: { projectId: project.parentId },
                query: getQueryParamProjectReadonly(),
              }">
                <div class="text-caption">→親プロジェクトへ移動</div>
              </router-link>
            </v-card>
          </div>
          <v-spacer></v-spacer>
          <v-col class="d-none d-md-flex justify-end align-center py-0">
            <project-participants></project-participants>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

import ProjectParticipants from "../organisms/ProjectParticipants";

export default {
  components: {
    ProjectParticipants,
  },
  props: {
    height: {
      require: true,
    },
  },
  computed: {
    ...mapGetters(["project"]),
  },
};
</script>
<style lang="scss" scoped>
.strategy-edit-topbar {
  .no-image-col {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    background-color: #888888;
  }
  &__label {
    .top {
      width: max-content;
    }
    .bottom {
      width: max-content;
      text-transform: none !important;
    }
  }
}
</style>
