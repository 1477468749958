<template>
  <div v-if="isInit">
    <multi-row-appbar-container
      :multi-row="true"
      :upper-bar-height="upperBarHeight"
      :lower-bar-height="lowerBarHeight"
    >
      <global-nav-header :height="upperBarHeight"></global-nav-header>
      <template #second>
        <strategy-edit-topbar :height="lowerBarHeight"></strategy-edit-topbar>
      </template>
    </multi-row-appbar-container>

    <strategy-edit-side-menu></strategy-edit-side-menu>
    <v-main
      id="StrategyPartsEdit_top"
      class="strategy-edit-main-container-wrap"
      :style="{scrollBehavior: scrollBehavior}"
      v-scroll.self="onScroll"
    >
      <router-view></router-view>
    </v-main>
    <v-scroll-y-reverse-transition>
      <v-btn
        v-if="scrollTop > 50"
        color="rgba(13,71,161, 0.7)"
        absolute right bottom
        fab tile
        width="55"
        height="40"
        elevation="5"
        class="chevron-up-button mr-3 mb-10"
        @click="moveTop()"
      >
        <v-icon color="white">mdi-chevron-up</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>
  </div>
</template>

<script>
import GlobalNavHeader from "@/components/atomic/organisms/GlobalNavHeader.vue";
import StrategyEditTopbar from "@/components/atomic/organisms/StrategyEditTopbar";
import StrategyEditSideMenu from "@/components/atomic/organisms/StrategyEditSideMenu.vue";
import MultiRowAppbarContainer from "@/components/atomic/organisms/MultiRowAppbarContainer";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    StrategyEditTopbar,
    GlobalNavHeader,
    StrategyEditSideMenu,
    MultiRowAppbarContainer,
  },
  data() {
    return {
      upperBarHeight: 50,
      lowerBarHeight: 75,
      isInit: false,
      scrollTop: 0,
      scrollBehavior: 'smooth',
    };
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {
    ...mapActions(["getProject"]),
    async init() {
      let readonly = this.$route.query?.mode == "readonly";
      console.log(this.$route.query)

      console.log("this.readonly:" + readonly);
      await this.getProject(this.$route.params.projectId);

      this.$store.commit("project_readonly", readonly ? true : false);

      //参照したProjectIdをcookieへ保存
      if (this.project) {
        const cookieVal = this.$cookies.get("sdb_projectIdHist");
        const hist = cookieVal ? JSON.parse(cookieVal) : [];

        if (hist.indexOf(this.project.id) < 0) {
          hist.unshift(this.project.id);

          this.$cookies.set(
            "sdb_projectIdHist",
            JSON.stringify(hist.slice(0, 5))
          );
        }
      }

      this.isInit = true;
    },
    onScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    moveTop() {
      document.getElementById('StrategyPartsEdit_top').scrollTop = 0;
    },
  },
  async created() {
    await this.init();
  },
  watch: {
    // プロジェクトが変わった場合にはデータを取得しなおす
    // urlで直接プロジェクトidが指定された時などを想定
    "$route.params.projectId": async function(to) {
      await this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.strategy-edit-main-container-wrap {
  @include scrollAreaY();
  height: 100vh;
}
.chevron-up-button {
  border-radius: 3px;
}
</style>
