<template>
  <v-dialog 
    v-model="open"
    max-width="1000"
    transition="dialog-bottom-transition"
  >
    <v-card class="user-edit-dialog">
      <v-toolbar dark flat color="#0761B4">
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      </v-toolbar>
      
      <v-card-text class="pa-3">
        <v-container>
          <v-row>
            <v-col cols="2">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-if="item.userImageUrl"
                  max-height="150"
                  max-width="150"
                  class="ml-auto"
                >
                  <v-img
                    :src="item.userImageUrl"
                    max-height="150"
                    max-width="150"
                    aspect-ratio="1.0"
                  ></v-img>
                  <v-btn
                    class="icon-btn"
                    :class="{ 'icon-btn--hover': hover }"
                    x-small
                    fab
                    color="primary"
                    @click="imageDialog = true"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                </v-card>
                <v-card v-else max-height="150" max-width="150">
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-overlay absolute>
                      <v-btn text color="white" @click="imageDialog = true">
                        アイコンを追加
                      </v-btn>
                    </v-overlay>
                  </v-responsive>
                </v-card>
              </v-hover>
              <br>
              <div class="text-subtitle-2 black--text">または<br>アイコンカラー</div>
              <br>
              <div
                class="user-color"
                :style="{backgroundColor: item.userColor}"
              >
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-responsive
                      :aspect-ratio="1/1"
                      v-on="on"
                    ></v-responsive>
                  </template>
                  <v-color-picker
                    v-model="userColor"
                    show-swatches
                    class="mx-auto"
                  ></v-color-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="10">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.lastName"
                    label="ユーザー（姓）"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.firstName"
                    label="ユーザー（名）"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.loginEmail"
                    label="メールアドレス"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!profile">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="!profile">
                <v-col cols="6">
                  <v-select
                    v-model="item.departmentId"
                    label="所属"
                    :items="departmentsList"
                    item-text="departmentName"
                    item-value="id"
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.position"
                    label="役割"
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!profile">
                <v-col cols="6">
                  <v-checkbox 
                    v-model="item.superUser"
                    label="スーパーユーザー"
                    hide-details
                    :disabled="!!item.partner"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox 
                    v-model="item.partner"
                    label="パートナー"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="add">
                <v-col cols="6">
                  <v-text-field
                    v-model="password"
                    :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="password_show ? 'text' : 'password'"
                    :rules="[rules.required, rules.min]"
                    label="パスワード"
                    counter
                    @click:append="password_show = !password_show"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="6">
                  <v-checkbox 
                    v-model="isChangePassword"
                    label="パスワードを変更する"
                    hide-details
                  ></v-checkbox>
                  <br>
                  <div v-show="isChangePassword">
                    <v-text-field
                      v-model="passwordOld"
                      v-if="!userInfo.superUser"
                      :append-icon="passwordOld_show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordOld_show ? 'text' : 'password'"
                      :rules="[rules.required, rules.min]"
                      label="現在のパスワード"
                      counter
                      @click:append="passwordOld_show = !passwordOld_show"
                    ></v-text-field>
                    <br>
                    <v-text-field
                      v-model="password"
                      :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="password_show ? 'text' : 'password'"
                      :rules="[rules.required, rules.min]"
                      label="新しいパスワード"
                      counter
                      @click:append="password_show = !password_show"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 mb-3"
          text
          width="190"
          @click="close"
          >キャンセル
        </v-btn>
        <v-btn
          color="btAccent"
          class="white--text mx-2 mb-3"
          width="190"
          depressed
          @click="save"
          >{{buttonTitle}}</v-btn
        >
      </v-card-actions>

      <image-select-dialog
        v-model="imageDialog"
        :src="item.userImageUrl"
        @change-url="item.userImageUrl = $event"
        caption="アイコンを変更"
        :func="5"
      ></image-select-dialog>

      <v-dialog v-model="infoDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-subtitle-2">{{info}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="infoDialog = !infoDialog">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-overlay
        :absolute="true"
        :value="overlay"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import ImageSelectDialog from "../../atoms/ImageSelectDialog.vue";
import {RepositoryFactory} from '../../../../api/RepositoryFactory'
import * as _ from 'lodash';

export default {
  components: {
    ImageSelectDialog,
  },
  props: ["value", "userData", "add", "profile"],
  data() {
    return {
      item: {
        userId: 0, 
        lastName: null, 
        firstName: null, 
        position: null, 
        superUser: false, 
        partner: false,
        userImageUrl: null, 
        userColor: '#ffffff', 
        loginEmail: null, 
        departmentId: 1, 
      },

      isChangePassword: false,
      password: '',
      passwordOld: '',
      password_show: false,
      passwordOld_show: false,
      

      formTitle: "ユーザー編集",
      buttonTitle: "保存",

      imageDialog: false,
      infoDialog: false,
      info: '',
      overlay: false,

      rules: {
        required: value => !!value || '必須です',
        min: v => v.length >= 8 || '8文字以上入力してください',
      },
    };
  },
  mounted() {
    if (this.add) {
      this.formTitle = "ユーザー追加";
      this.buttonTitle = "追加";
    } else {
      this.formTitle = "ユーザー編集";
      this.buttonTitle = "保存";
    }
  },
  computed: {
    ...mapGetters(["userInfo", "departmentsList"]),

    open: {
      get() { return !!this.value },
      set(val) {
        this.$emit('input', val);
      },
    },

    userColor: {
      get() {
        return this.item.userColor;
      },
      set(val) {
        this.item.userColor = val?.hex ? val.hex : val;
      }
    }
  },
  methods: {
    close() {
      this.open = false;
    },
    async save() {
      if(
        !this.item.lastName ||
        !this.item.firstName ||
        !this.item.loginEmail
      ) {
        this.info = 'ユーザー名、メールアドレスは必須です';
        this.infoDialog = true;
        return;
      }

      if(this.add && !this.password) {
        this.info = 'パスワードを入力してください';
        this.infoDialog = true;
        return;
      } else if(this.isChangePassword && (!this.password || !(this.passwordOld || this.userInfo.superUser ))) {
        this.info = 'パスワードを入力してください';
        this.infoDialog = true;
        return;
      }

      //ユーザー情報を保存
      this.overlay = true;

      const payload = {
        user: this.item,
        userId: this.userInfo.userId,
      };

      if(this.add) {
        payload.hashString = this.password;
      } else if(this.isChangePassword) {
        payload.hashString = this.password;
        payload.hashString2 = this.passwordOld;
      }

      const UserRepository = RepositoryFactory.get( 'users' );
      const response = await UserRepository.update(payload);
      if (response.data.result && response.data.resultCode === '0000') {

        this.$emit('ok', response.data.data.users.length > 0 ? response.data.data.users[0] : null);
        this.close();
      } else if(response.data.errorCode == 'MSGCO-BTAPI-0201') {
        //パスワードエラー
        this.info = '現在のパスワードが一致しません';
        this.infoDialog = true;

      } else if(response.data.errorCode == 'MSGCO-BTAPI-0202') {
        //メールアドレス重複エラー
        this.info = 'メールアドレスは既に使用されています';
        this.infoDialog = true;

      } else {
        this._sendError( response.data.message );
      }

      this.overlay = false;
    },
  },
  watch: {
    userData(val) {
      if(val) {
        _.assign(this.item, val);
      } else {
        _.assign(this.item, {
          userId: 0, 
          lastName: null, 
          firstName: null, 
          position: null, 
          superUser: false, 
          partner: false,
          userImageUrl: null, 
          userColor: '#ffffff', 
          loginEmail: null, 
          departmentId: this.departmentsList[0].departmentId, 
        });
      }

      this.isChangePassword = false;
      this.password = '';
      this.passwordOld = '';
      this.password_show = false;
      this.passwordOld_show = false;
    },
    add(val) {
      if (val) {
        this.formTitle = "ユーザー追加";
        this.buttonTitle = "追加";
      } else {
        this.formTitle = "ユーザー編集";
        this.buttonTitle = "保存";
      }
    },
    "item.partner"(val) {
      if(val) {
        this.item.superUser = false;
      }
    }
  },



}
</script>
<style lang="scss" scoped>
.user-edit-dialog {
  .icon-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;

    &--hover {
      opacity: 1;
    }
  }

  .user-color {
    width: 60%;
    border-radius: 4px;
    border: solid 1px #AFAFAF;
    cursor: pointer;

  }
}
</style>
